import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { v4 as guid } from 'uuid'
import { useRealtimeDraftView } from './realtimeDraftView'
import { isEqual, cloneDeep, find, orderBy } from 'lodash'
import { FilterCombiner, ParameterType } from '../grpc/enums'
import { useFilterSourceOptions } from './filterSourceOptions'
import { useCanvasItem } from './canvasItem'
import { useGrpcCallback } from '../grpc'
import { useNotification } from '../hooks/useNotification'
import { useAuth } from './auth'
import {
  toDeleteCanvasTileUserQueryParametersRequest,
  toQueryParameters,
  toUpsertCanvasTileUserQueryParametersRequest
} from '../grpc/converters'
import { opportunityFilterKey } from '../constants/filterKeys'

const RealtimeFilterEditorContext = React.createContext()

export function RealtimeFilterEditorProvider({ selectedFilter, children }) {
  const { notifyError } = useNotification()
  const { actingTenantId, actingUserId } = useAuth()
  const { isFetching: isFetchingSourceOptions, sourceOptions } = useFilterSourceOptions()

  const {
    isFetching: isFetchingCanvasItem,
    isUpserting: isUpsertingCanvasItem,
    invalidate: invalidateCanvasItem,
    orgDefaultUserQueryParameter,
    defaultUserFilter,
    defaultOrgFilter,
    userQueryParametersList,
    availableParameters,
    canvasKey,
    tileKey,
    queryKey,
    pendingFilter,
    setPendingFilter,
    setOrgDefault
  } = useCanvasItem()

  const {
    setSelectedFilterId,
    selectedFilter: selectedDraftFilter,
    setSelectedFilter: setSelectedDraftFilter,
    setOverrideParametersList,
    invalidate: invalidateRealtimeDraftView
  } = useRealtimeDraftView()

  const [previousWorkingFilter, setPreviousWorkingFilter] = useState(undefined)
  const [workingFilter, setWorkingFilter] = useState(undefined)
  const [isUpserting, setIsUpserting] = useState(false)

  useEffect(() => {
    if (!isFetchingSourceOptions && selectedFilter) {
      setPreviousWorkingFilter(pendingFilter || cloneDeep(selectedFilter))
      setWorkingFilter(cloneDeep(selectedFilter))
      if (pendingFilter) {
        setOverrideParametersList(undefined)
        setSelectedDraftFilter(pendingFilter)
      }
    }
  }, [isFetchingSourceOptions, selectedFilter, pendingFilter, selectedDraftFilter])

  useEffect(() => {
    const pendFilter = find(userQueryParametersList, (uqp) => uqp.isPending)
    if (pendFilter) {
      const filter = find(userQueryParametersList, (uqp) => uqp.id === pendFilter.id && !uqp.isPending)
      if (filter) {
        // the pendingFilter and the saved version of the pendingFilter was found in the userQueryParametersList
        // so now we can unset the pendingFilter since the pendingFilter was just saved
        setPendingFilter(undefined)
        setSelectedDraftFilter(filter)
      }
    }
  }, [userQueryParametersList])

  useEffect(() => {
    if (!isFetchingCanvasItem && !workingFilter) {
      if (defaultUserFilter) {
        setPreviousWorkingFilter(defaultUserFilter)
        setWorkingFilter(defaultUserFilter)
        setSelectedDraftFilter(defaultUserFilter)
      } else if (defaultOrgFilter) {
        setPreviousWorkingFilter(defaultOrgFilter)
        setWorkingFilter(defaultOrgFilter)
        setSelectedDraftFilter(defaultOrgFilter)
      } else {
        setPreviousWorkingFilter(userQueryParametersList[0])
        setWorkingFilter(userQueryParametersList[0])
        setSelectedDraftFilter(userQueryParametersList[0])
      }
    }
  }, [setSelectedDraftFilter, isFetchingCanvasItem, workingFilter, userQueryParametersList, defaultUserFilter, defaultOrgFilter])

  const updateParametersList = useCallback((newParametersList = []) => {
    const clone = cloneDeep(workingFilter)
    if (clone?.parameters?.parametersList) {
      clone.parameters.parametersList = newParametersList
    }
    setWorkingFilter(clone)
    return clone
  }, [workingFilter])

  const parametersList = useMemo(() => {
    const params = (availableParameters?.parametersList ?? [])
      .map((aqp) => ({
        available: aqp,
        user: workingFilter?.parameters?.parametersList?.find(({ key }) => key === aqp.key)
      }))
      .filter((p) => {
        return p.available.visible
      })
    const opportunityFilterParam = params.find(p => p.available.key === opportunityFilterKey)
    if (opportunityFilterParam) {
      return [...params.filter(p => p !== opportunityFilterParam), opportunityFilterParam]
    } else {
      return params
    }
  }, [availableParameters, workingFilter])

  const createFilter = useCallback(() => {
    const [{ source }] = sourceOptions
    return {
      combiner: FilterCombiner.AND,
      nestedList: [],
      key: guid(),
      filter: {
        key: guid(),
        source,
        field: null,
        fieldType: null,
        comparisonOp: null,
        valuesList: []
      }
    }
  }, [sourceOptions])

  const createFilterWrapper = useCallback(() => {
    return {
      key: guid(),
      nestedList: [
        createFilter()
      ],
      combiner: FilterCombiner.AND
    }
  }, [createFilter])

  const getParameterForType = useCallback((parameterType) => {
    if (!parameterType) {
      return
    }
    switch (parameterType) {
      case ParameterType.PARAMETER_TYPE_FILTER:
        return {
          key: 'filter',
          prop: {
            filters: createFilterWrapper()
          }
        }
      case ParameterType.PARAMETER_TYPE_RESOLVED_QUERY:
        return {
          key: 'resolvedQuery',
          prop: { queriesList: [{}] }
        }
      case ParameterType.PARAMETER_TYPE_VALUE:
        return {
          key: 'value',
          prop: {}
        }
      default:
        return { key: '', prop: null }
    }
  }, [createFilterWrapper])

  const addNewParameter = useCallback((key, type) => {
    const parametersList = workingFilter?.parameters?.parametersList ?? []
    const newParam = getParameterForType(type)
    const updatedParams = cloneDeep(parametersList).map((p) => {
      if (p.key === key && newParam) {
        p[newParam.key] = newParam.prop
      }
      return p
    })
    updateParametersList(updatedParams)
  }, [workingFilter, updateParametersList, getParameterForType])

  const canApply = useMemo(() => {
    const equal = isEqual(orderBy(previousWorkingFilter?.parameters?.parametersList, (p) => p.key), orderBy(workingFilter?.parameters?.parametersList, (p) => p.key))
    return !equal
  }, [previousWorkingFilter, workingFilter])

  const applyWorkingFilter = useCallback(() => {
    const parametersList = workingFilter?.parameters?.parametersList ?? []
    setOverrideParametersList(parametersList)
    const appliedFilter = updateParametersList(parametersList)
    setPreviousWorkingFilter(cloneDeep(appliedFilter))
  }, [workingFilter, setOverrideParametersList, updateParametersList])

  const upsertCanvasTileUserQueryParameters = useGrpcCallback({
    onSuccess: (obj, { originalId, isOrgDefaultFilter }) => {
      // when saving an filter, if the id that comes back is a new id
      // we need to reset the selectedFilter using the new id
      if (originalId !== obj.id) {
        setSelectedFilterId(obj.id)
        if (isOrgDefaultFilter) {
          setOrgDefault(obj.id)
          setIsUpserting(false)
          return
        }
      }
      invalidateCanvasItem()
      setIsUpserting(false)
    },
    onError: (err) => {
      setIsUpserting(false)
      notifyError('Error saving filter')
    },
    onFetch: () => setIsUpserting(true),
    grpcMethod: 'upsertCanvasTileUserQueryParameters',
    debug: false
  }, [])

  const canSave = useMemo(() => {
    if (isUpserting || isFetchingCanvasItem || isUpsertingCanvasItem) {
      return false
    }
    if (pendingFilter) {
      return true
    }
    // always grab the filter from the CanvasItemContextProvider when comparing against the workingFilter
    // also, run the savedFilter and workingFilter through conversions to protos and back to json objects before comparing
    const savedFilter = find(userQueryParametersList, (uqp) => uqp.id === selectedDraftFilter?.id)
    const savedFilterName = savedFilter?.name ?? ''
    const savedFilterParameters = savedFilter ? toQueryParameters({ parametersList: orderBy(savedFilter.parameters?.parametersList, (p) => p.key) }).toObject() : []
    const workingFilterName = workingFilter?.name ?? ''
    const workingFilterParameters = workingFilter ? toQueryParameters({ parametersList: orderBy(workingFilter.parameters?.parametersList, (p) => p.key) }).toObject() : []
    const equal = isEqual(savedFilterParameters.parametersList, workingFilterParameters.parametersList)
    return !equal || savedFilterName !== workingFilterName
  }, [isUpserting, isFetchingCanvasItem, isUpsertingCanvasItem, userQueryParametersList, selectedDraftFilter, workingFilter, pendingFilter])

  const saveWorkingFilter = useCallback(() => {
    const request = toUpsertCanvasTileUserQueryParametersRequest({
      tenantId: actingTenantId,
      userId: workingFilter.userId,
      canvasKey,
      tileKey,
      parameters: workingFilter
    })
    upsertCanvasTileUserQueryParameters(
      request,
      {
        originalId: workingFilter?.id,
        isOrgDefaultFilter: workingFilter?.id === orgDefaultUserQueryParameter
      }
    )
  }, [actingTenantId, canvasKey, tileKey, upsertCanvasTileUserQueryParameters, workingFilter, orgDefaultUserQueryParameter])

  const setShared = useCallback(() => {
    const parameters = cloneDeep(workingFilter)
    parameters.id = guid()
    parameters.userId = ''
    const request = toUpsertCanvasTileUserQueryParametersRequest({
      tenantId: actingTenantId,
      userId: '',
      canvasKey,
      tileKey,
      parameters
    })
    upsertCanvasTileUserQueryParameters(request)
  }, [actingTenantId, canvasKey, tileKey, upsertCanvasTileUserQueryParameters, workingFilter])

  const renameWorkingFilter = useCallback((newName) => {
    const filter = cloneDeep(workingFilter)
    filter.name = newName
    setWorkingFilter(filter)
  }, [workingFilter])

  const discardChanges = useCallback(() => {
    const savedFilter = find(userQueryParametersList, (uqp) => uqp.id === selectedDraftFilter?.id)
    setSelectedDraftFilter(savedFilter)
    setPreviousWorkingFilter(cloneDeep(savedFilter))
    setWorkingFilter(cloneDeep(savedFilter))
    invalidateRealtimeDraftView()
  }, [userQueryParametersList, selectedDraftFilter, setSelectedDraftFilter, invalidateRealtimeDraftView])

  const deleteCanvasTileUserQueryParameters = useGrpcCallback({
    onSuccess: (res) => {
      invalidateCanvasItem()
      setWorkingFilter(undefined)
    },
    onError: (err) => {
      notifyError('Error deleting filter')
    },
    grpcMethod: 'deleteCanvasTileUserQueryParameters',
    debug: false
  }, [])

  const deleteFilter = useCallback(() => {
    if (workingFilter) {
      const request = toDeleteCanvasTileUserQueryParametersRequest({
        tenantId: actingTenantId,
        userId: workingFilter.userId,
        canvasKey,
        tileKey,
        userQueryParametersId: workingFilter.id
      })
      deleteCanvasTileUserQueryParameters(request)
    }
  }, [actingTenantId, canvasKey, tileKey, deleteCanvasTileUserQueryParameters, workingFilter])

  const duplicateFilter = useCallback(() => {
    if (workingFilter) {
      const dupe = cloneDeep(workingFilter)
      dupe.userId = actingUserId
      dupe.id = guid()
      dupe.name = `${workingFilter.name} Copy`.trim()
      dupe.editName = true
      dupe.isPending = true
      setPendingFilter(dupe)
    }
  }, [actingUserId, workingFilter, setPendingFilter])

  const createNewFilter = useCallback(() => {
    const newFilter = {
      id: guid(),
      tenantId: actingTenantId,
      userId: actingUserId,
      queryKey,
      name: 'New Filter',
      description: '',
      sort: 1,
      parameters: {
        parametersList: availableParameters?.parametersList ?? []
      },
      editName: true,
      isPending: true
    }
    setPendingFilter(newFilter)
  }, [actingTenantId, actingUserId, queryKey, availableParameters, setPendingFilter])

  const getParametersList = useCallback((parameterKey, callback) => {
    return workingFilter?.parameters?.parametersList?.map((p) => {
      if (p?.key === parameterKey) {
        if (!p?.filter?.filters) {
          return p
        }
        const { filters } = p.filter
        const updatedFilters = callback(filters)

        const filter = (updatedFilters)
          ? { filters: updatedFilters } : undefined

        return {
          ...p,
          filter
        }
      }
      return p
    })
  }, [workingFilter])

  const addFilterToNested = useCallback((parameterKey, newFilter, origNestedList) => {
    const insertFilter = (filters) => {
      const { nestedList } = filters
      if (Object.is(nestedList, origNestedList)) {
        return {
          ...filters,
          nestedList: [
            ...nestedList,
            newFilter
          ]
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => insertFilter(nf))
        }
      }
      return filters
    }

    const parametersList = workingFilter?.parameters?.parametersList?.map((p) => {
      if (p?.key === parameterKey) {
        const { filters = {} } = p?.filter ?? {}
        return {
          ...p,
          filter: {
            filters: insertFilter(filters)
          }
        }
      }
      return p
    })

    updateParametersList(parametersList)
  }, [workingFilter, updateParametersList])

  const addGroupToNested = useCallback((parameterKey, newGroup, origNestedList) => {
    const insertGroup = (filters) => {
      const { nestedList } = filters
      if (Object.is(nestedList, origNestedList)) {
        if (origNestedList.length && !origNestedList[0].filter) {
          return {
            ...filters,
            nestedList: [
              ...nestedList,
              newGroup
            ]
          }
        }
        const newWrapper = createFilterWrapper()
        newWrapper.nestedList = [...nestedList]
        return {
          ...filters,
          nestedList: [
            newWrapper,
            newGroup
          ]
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => insertGroup(nf))
        }
      }
      return filters
    }

    const parametersList = workingFilter?.parameters?.parametersList?.map((p) => {
      if (p?.key === parameterKey) {
        const { filters = {} } = p?.filter ?? {}
        return {
          ...p,
          filter: {
            filters: insertGroup(filters)
          }
        }
      }
      return p
    })

    updateParametersList(parametersList)
  }, [workingFilter, createFilterWrapper, updateParametersList])

  const setFilterValue = useCallback((parameterKey, updatedFilter, origFilter) => {
    const replaceFilter = (filters) => {
      const { filter, nestedList } = filters
      if (Object.is(filter, origFilter)) {
        return {
          ...filters,
          filter: updatedFilter
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => replaceFilter(nf))
        }
      }
      return filters
    }

    const parametersList = getParametersList(parameterKey, replaceFilter)
    updateParametersList(parametersList)
  }, [updateParametersList, getParametersList])

  const removeFilter = useCallback((parameterKey, origFilter) => {
    const replaceFilter = (filters) => {
      const { filter, nestedList } = filters
      if (Object.is(filter, origFilter)) {
        return
      }

      if (nestedList?.length) {
        const newNested = nestedList.map(replaceFilter).filter((f) => f)

        if (!newNested.length && !filters?.filter) {
          return
        }

        if (newNested.length === 1 && !newNested[0]?.filter) {
          return newNested[0]
        }

        return {
          ...filters,
          nestedList: newNested
        }
      }
      return filters
    }

    const parametersList = getParametersList(parameterKey, replaceFilter)
    updateParametersList(parametersList)
  }, [updateParametersList, getParametersList])

  const setCombiner = useCallback((parameterKey, updatedCombiner, origFilters) => {
    const replaceFilter = (filters) => {
      const { nestedList } = filters
      if (Object.is(filters, origFilters)) {
        return {
          ...filters,
          combiner: updatedCombiner
        }
      }

      if (nestedList?.length) {
        return {
          ...filters,
          nestedList: nestedList.map((nf) => replaceFilter(nf))
        }
      }
      return filters
    }

    const parametersList = getParametersList(parameterKey, replaceFilter)
    updateParametersList(parametersList)
  }, [updateParametersList, getParametersList])

  const setValue = useCallback((parameterKey, value) => {
    const parametersList = workingFilter?.parameters?.parametersList?.map((p) => {
      if (p?.key === parameterKey) {
        return {
          ...p,
          value
        }
      }
      return p
    })
    updateParametersList(parametersList)
  }, [updateParametersList, workingFilter])

  const setResolvedQuery = useCallback((parameterKey, value) => {
    const parametersList = workingFilter?.parameters?.parametersList?.map((p) => {
      if (p?.key === parameterKey) {
        return {
          ...p,
          resolvedQuery: {
            queriesList: [{
              key: value.value
            }]
          }
        }
      }
      return p
    })
    updateParametersList(parametersList)
  }, [updateParametersList, workingFilter])

  const addFilter = useCallback((parameterKey, targetList) => {
    const newFilter = createFilter()
    addFilterToNested(parameterKey, newFilter, targetList)
  }, [addFilterToNested, createFilter])

  const addGroup = useCallback((parameterKey, targetFilter) => {
    const newFilter = createFilterWrapper()
    addGroupToNested(parameterKey, newFilter, targetFilter)
  }, [addGroupToNested, createFilterWrapper])

  const contextValue = useMemo(() => {
    return {
      workingFilter,
      parametersList,
      canApply,
      canSave,
      addNewParameter,
      addFilter,
      addGroup,
      removeFilter,
      setCombiner,
      setValue,
      applyWorkingFilter,
      saveWorkingFilter,
      renameWorkingFilter,
      discardChanges,
      deleteFilter,
      duplicateFilter,
      createNewFilter,
      setShared,
      setResolvedQuery,
      setFilterValue
    }
  }, [
    workingFilter,
    parametersList,
    canApply,
    canSave,
    addNewParameter,
    addFilter,
    addGroup,
    removeFilter,
    setCombiner,
    setValue,
    applyWorkingFilter,
    saveWorkingFilter,
    renameWorkingFilter,
    discardChanges,
    deleteFilter,
    duplicateFilter,
    createNewFilter,
    setShared,
    setResolvedQuery,
    setFilterValue
  ])

  return <RealtimeFilterEditorContext.Provider value={contextValue}>{children}</RealtimeFilterEditorContext.Provider>
}

export function useRealtimeFilterEditor() {
  const context = React.useContext(RealtimeFilterEditorContext)
  if (context === undefined) {
    throw new Error('useRealtimeFilterEditor must be used within a RealtimeFilterEditorProvider')
  }
  return context
}
