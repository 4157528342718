import React, { useEffect, useCallback, useState, useMemo } from 'react'
import FieldSelect from './fields/fieldSelect'
import { useFilterSourceOptions } from '../../context/filterSourceOptions'
import { useRealtimeFilterEditor } from '../../context/realtimeFilterEditor'
import { ParameterType } from '../../grpc/enums'

const ValueParameter = (props) => {
  const {
    user,
    parameter
  } = props

  const { setValue, addNewParameter } = useRealtimeFilterEditor()
  const { sourceOptions } = useFilterSourceOptions()

  const { options, key: parameterKey, label } = parameter
  const { value = {} } = user ?? {}
  const { fieldType } = value

  const [source] = options?.sourcesList ?? [{}]
  const { fieldsList, source: sourceType } = source
  const [field] = fieldsList ?? [{}]
  const { valuesList = [] } = field

  const [inputValue, setInputValue] = useState()

  const opts = useMemo(() => {
    if (!sourceType) {
      return valuesList
    } else {
      const fieldTypes = (fieldsList ?? []).map(f => f.fieldType).slice(0, 1)
      const s = sourceOptions.find(so => so.source === sourceType)
      return s?.fieldsList?.filter(o => fieldTypes.includes(o.fieldType)).map(f => ({ label: f.label, value: f.field })) ?? []
    }
  }, [valuesList, sourceType, sourceOptions, fieldsList])

  useEffect(() => {
    if (!opts?.length) {
      return
    }
    
    const selected = opts.find((vl) => vl.value === value?.value)
    if (!selected) {
      return
    }
    setInputValue([selected])
  }, [value, opts])

  const handleAddValue = useCallback(() => {
    addNewParameter(parameter, ParameterType.PARAMETER_TYPE_VALUE)
  }, [parameter, addNewParameter])

  const handleValueChange = useCallback((options) => {
    const [option] = options

    setInputValue(options)
    setValue(parameterKey, {
      value: option?.value,
      fieldType
    })
  }, [setValue, fieldType])

  return (
    <div className="self-start -ml-4">
      {user
        ? (
          <div className="flex flex-col mr-4 last:mr-0">
            <div className="mb-1 pl-4 text-color-51636a text-size-16px font-weight-500">{label}</div>
            <FieldSelect
              fullWidth
              onChange={handleValueChange}
              selectedDisplay={inputValue?.map(({ label }) => label).join(', ')}
              getIsSelected={({ value }) => inputValue?.find((iv) => iv.value === value)}
              values={opts} />
          </div>
        )
        : (
          <div className="filters-actions self-start">
            <button onClick={handleAddValue}>+ Add Value</button>
          </div>
        )}
    </div>
  )
}

export default ValueParameter
