import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { v4 as guid } from 'uuid'
import { useNotification } from '../hooks/useNotification'
import { useCanvasItem } from './canvasItem'
import { useGrpcCallback } from '../grpc'
import { toGetObjectMappingsSourceOptionsRequest } from '../grpc/converters'

const FilterSourceOptionsContext = React.createContext()

export const FilterSourceOptionsProvider = ({ isModalOpen, children }) => {
  const { notifyError } = useNotification()
  const { availableParameters } = useCanvasItem()

  const [key, setKey] = useState(guid())
  const [isFetching, setIsFetching] = useState(false)
  const [sourceOptions, setSourceOptions] = useState([])

  const getObjectMappingsSourceOptions = useGrpcCallback({
    onSuccess: ({ resultsList }) => {
      setSourceOptions(resultsList)
      setIsFetching(false)
    },
    onError: (err) => {
      setIsFetching(false)
      notifyError('Error getting mapping source options')
    },
    onFetch: () => setIsFetching(true),
    grpcMethod: 'getObjectMappingsSourceOptions',
    debug: false
  }, [])

  useEffect(() => {
    if (isModalOpen) {
      const parametersList = availableParameters?.parametersList ?? []
      const sources = {}
      parametersList.forEach(({ options = {} }) => {
        const { sourcesList = [] } = options
        sourcesList.forEach((s) => {
          const { source } = s
          sources[source] = s
        })
      })
      const requestedOptions = []
      Object.values(sources)
        .forEach(({ source, fieldsList = [] }) => {
          if (source) {
            requestedOptions.push({
              source,
              fieldsList
            })
          }
        })
      if (requestedOptions.length) {
        const request = toGetObjectMappingsSourceOptionsRequest({
          requestedOptions
        })
        getObjectMappingsSourceOptions(request)
      }
    }
  }, [key, isModalOpen])

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const contextValue = useMemo(() => {
    return {
      key,
      isFetching,
      sourceOptions,
      invalidate
    }
  }, [key, isFetching, sourceOptions, invalidate])

  return <FilterSourceOptionsContext.Provider value={contextValue}>{children}</FilterSourceOptionsContext.Provider>
}

export const useFilterSourceOptions = () => {
  const context = React.useContext(FilterSourceOptionsContext)
  if (context === undefined) {
    throw new Error('useFilterSourceOptions must be used within a FilterSourceOptionsProvider')
  }
  return context
}
