import React, { useCallback, useMemo, useState } from 'react'
import { BasicStatus } from '../grpc/enums'
import { format } from 'date-fns'
import { guid } from '../lib/guid'
import { useForecastConfigs } from './forecastConfigs'
import { useGroups } from './groups'
import { useTenantInfo } from './tenantInfo'
import { useGrpcCallback } from '../grpc'
import {
  toGetForecastRequest,
  toSaveForecastRequest,
  toSearchForecastsRequest,
  toExportSubmissionHistoryRequest
} from '../grpc/converters'
import { useForecastingFeature } from '../components/forecasting/hooks'
import { cloneDeep, filter, find, forOwn, orderBy, has, uniqBy, keys } from 'lodash'
import { useAuth } from './auth'
import { useNotification } from '../hooks/useNotification'
import { downloadFile } from '../lib/gCloudStorage'

const ForecastingContext = React.createContext()

export function ForecastingProvider({ children }) {
  const { actingUserId } = useAuth()
  const { findGroupById, getSubTreesForId } = useGroups()
  const { selectedConfig, setColumnsList } = useForecastConfigs()
  const { forecastTeamSettings } = useTenantInfo()
  const { enabled, enrichForecast } = useForecastingFeature()
  const { notifySuccess } = useNotification()

  const [key, setKey] = useState(guid())
  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingHistory, setIsFetchingHistory] = useState(true)
  const [success, setSuccess] = useState(false)
  const [successHistory, setSuccessHistory] = useState(false)
  const [error, setError] = useState(null)
  const [forecast, setForecast] = useState({})
  const [forecastHistory, setForecastHistory] = useState({})
  const [forecastLocalOppSelections, setForecastLocalOppSelections] = useState([])
  const [forecastByUserLocal, setForecastByUserLocal] = useState({})
  const [cursor, setCursor] = useState(0)

  const invalidate = useCallback(() => {
    setKey(guid())
  }, [])

  const teamForecasts = useMemo(() => {
    const { teamForecastsList = [] } = forecast
    const userData = teamForecastsList.map((d) => {
      return {
        info: enrichForecast(d.view),
        user: findGroupById(d.ownerId, { includeDescendants: true, depth: 1 }),
      }
    })
    return orderBy(filter(userData, (u) => u.user && u.user?.status === BasicStatus.ACTIVE), (o) => o.user.name.toLowerCase())
  }, [forecast, findGroupById, enrichForecast])

  const onSuccess = useCallback(({ viewsList = [] }) => {
    const [forecastView] = viewsList

    const config = forecastView.forecastConfig ?? selectedConfig
    if (config) {
      setColumnsList(orderBy(config.columnsList, ['sort']))
    }

    setForecast(enrichForecast(forecastView))
    setIsFetching(false)
    setSuccess(true)
  }, [enrichForecast, setColumnsList, selectedConfig])

  const onError = useCallback((err) => {
    setError(err)
    setIsFetching(false)
  }, [])

  const onFetch = useCallback(() => {
    setIsFetching(true)
    setSuccess(false)
    setError(null)
  }, [])

  const teamSettingsByGroupId = useCallback((ownerId) => {
    const groupByOwnerId = findGroupById(ownerId)

    if (!groupByOwnerId) {
      return
    }

    const { childrenIdsList = [], membersIdsList = [] } = groupByOwnerId
    return {
      ...forecastTeamSettings,
      levelChildGroupsList: childrenIdsList,
      levelMembersList: membersIdsList
    }
  }, [findGroupById, forecastTeamSettings])

  // service calls //

  const getCurrentRepForecastByConfigRequest = useGrpcCallback({
    onSuccess,
    onFetch,
    onError,
    grpcMethod: 'getRepForecastV3',
    debug: false
  }, [key, onSuccess, onFetch, onError])

  const getCurrentRepForecastByConfig = useCallback((config) => {
    const c = config || selectedConfig
    if (!c) {
      return
    }
    const request = toGetForecastRequest({
      config: c,
      ownerId: actingUserId,
      period: {
        type: c.periodLength,
        cursor
      },
      teamSettings: forecastTeamSettings
    })
    getCurrentRepForecastByConfigRequest(request)
  }, [selectedConfig, actingUserId, cursor, forecastTeamSettings, getCurrentRepForecastByConfigRequest])

  const getCurrentForecastByConfigRequest = useGrpcCallback({
    onSuccess,
    onFetch,
    onError,
    grpcMethod: 'getManagerForecastV3',
    debug: false
  }, [key, onSuccess, onFetch, onError])

  const getCurrentForecastByConfig = useCallback((config, ownerId) => {
    const teamSettings = teamSettingsByGroupId(ownerId)

    if (!teamSettings) {
      return
    }
    const request = toGetForecastRequest({
      config,
      ownerId,
      period: {
        type: config.periodLength,
        cursor
      },
      teamSettings
    })
    getCurrentForecastByConfigRequest(request)
  }, [getCurrentForecastByConfigRequest, teamSettingsByGroupId, cursor])

  const exportSubmissionHistoryRequest = useGrpcCallback({
    onSuccess: (obj) => {
      const { url } = obj
      if (url) {
        downloadFile({
          url,
          contentType: 'text/csv',
          fileName: `ForecastHistory-${selectedConfig.name}-${format(new Date(), 'MM-dd-yyy')}.csv`,
          onError: () => {
            setError('Unable to download forecast history, please try again.')
          }
        })
      }
    },
    onFetch: () => {
      console.log('fetching')
    },
    onError: () => {
      console.log('error exporting submission history')
    },
    grpcMethod: 'exportSubmissionHistory',
    debug: false
  }, [selectedConfig])

  const exportSubmissionHistory = useCallback((searchObject) => {
    const { period, ownerId, limit, page, searchText, filtersList, sortOptionsList, configId, groupName } = searchObject
    const request = toExportSubmissionHistoryRequest({
      searchRequest: {
        ...searchText && { searchText },
        ...filtersList && { filtersList },
        ...sortOptionsList && { sortOptionsList },
        forecastConfigIdsList: [configId],
        limit,
        ownerId,
        page,
        period,
      },
      forecastConfig: selectedConfig,
      groupName
    })
    exportSubmissionHistoryRequest(request)
  }, [exportSubmissionHistoryRequest, selectedConfig])

  const searchForecastsRequest = useGrpcCallback({
    onSuccess: (obj, data) => {
      const { forecastsList } = obj
      setForecastHistory({
        ...obj,
        ...data,
        forecastsList: forecastsList.map((f) => enrichForecast({ current: f }))
      })
      setIsFetchingHistory(false)
      setSuccessHistory(true)
    },
    onFetch: () => {
      setIsFetchingHistory(true)
      setSuccessHistory(false)
    },
    onError: () => {
      setIsFetchingHistory(false)
      setSuccessHistory(false)
    },
    grpcMethod: 'searchForecastsV3',
    debug: false
  }, [key, onFetch, onError, enrichForecast])

  const searchForecasts = useCallback((searchObject) => {
    const { period, ownerId, limit, page, searchText, filtersList, sortOptionsList, configId } = searchObject
    const request = toSearchForecastsRequest({
      ...searchText && { searchText },
      ...filtersList && { filtersList },
      ...sortOptionsList && { sortOptionsList },
      forecastConfigIdsList: [configId],
      limit,
      ownerId,
      page,
      period
    })
    searchForecastsRequest(request, { limit, page })
  }, [searchForecastsRequest])

  const saveForecastedCallsRequest = useGrpcCallback({
    onSuccess: () => {
      getCurrentRepForecastByConfig()
    },
    onFetch,
    onError,
    grpcMethod: 'saveForecastV3',
    grpcMethodName: 'saveForecastedCalls',
    debug: false
  }, [key, onFetch, onError, getCurrentRepForecastByConfig])

  const saveForecastedCalls = useCallback((forecastCalls) => {
    const f = cloneDeep(forecast)
    if (f.current) {
      if (!f?.current?.callsList) {
        f.current.callsList = []
      }
      forOwn(forecastCalls, (value, key) => {
        const forecastCategoryCall = find(f.current.callsList, (c) => c.key === key)
        if (forecastCategoryCall) {
          forecastCategoryCall.value = { value, valid: true }
        }
      })
      const newForecast = cloneDeep(f)
      // only send calls that were made
      const forecastCategories = keys(forecastCalls)
      f.current.callsList = filter(f.current.callsList, (c) => forecastCategories.includes(c.key))
      f.current.callsList = filter(f.current.callsList, (c, k) => k === 0)
      f.current.ownerId = f.ownerId
      const request = toSaveForecastRequest({
        forecast: f.current,
        teamSettings: forecastTeamSettings
      })
      saveForecastedCallsRequest(request, { forecast: newForecast })
    }
  }, [forecast, saveForecastedCallsRequest, forecastTeamSettings])

  const submitForecastRequest = useGrpcCallback({
    onSuccess: (obj, data) => {
      const { callsList } = obj
      callsList.forEach(({ ownerId, forecastId }) => {
        const update = {
          forecast: { current: { ownerId } },
          forecastCategory: { id: forecastId }
        }
        removeForecastByUserLocalCategoryCall(update)
      })
      if (!data?.isManager) {
        getCurrentRepForecastByConfig()
      } else {
        getCurrentForecastByConfig(selectedConfig, data?.ownerId)
      }
      notifySuccess('Forecast Submitted')
    },
    onFetch,
    onError,
    grpcMethod: 'submitForecastV3',
    debug: false
  }, [key, selectedConfig, onFetch, onError, getCurrentRepForecastByConfig, getCurrentForecastByConfig, removeForecastByUserLocalCategoryCall])

  const submitForecast = useCallback((comment = '', callsListMap, isManager) => {
    const f = cloneDeep(forecast.current, {})

    f.comment = comment
    f.ownerId = forecast.ownerId
    f.forecastConfigId = selectedConfig?.id

    f.callsList.forEach((call) => {
      const { key } = call
      const value = callsListMap.get(key)
      call.value = { valid: true, value }
    })

    const request = toSaveForecastRequest({
      forecast: f,
      forecastTeamSettings
    })
    submitForecastRequest(request, { isManager, ownerId: forecast.ownerId })
  }, [forecast, selectedConfig?.id, forecastTeamSettings, submitForecastRequest])

  // storage methods //

  const clearLocalForecastOpps = useCallback(() => {
    setForecastLocalOppSelections([])
  }, [])

  const clearForecastByUserLocal = useCallback(() => {
    setForecastByUserLocal({})
  }, [])

  const clearForecastHistory = useCallback(() => {
    setForecastHistory({})
  }, [])

  const updateForecastByUserLocal = useCallback((update) => {
    const userLocal = cloneDeep(forecastByUserLocal)
    const { ownerId, forecastCategory, call } = update
    if (!userLocal[ownerId]) {
      userLocal[ownerId] = {}
    }
    if (userLocal[ownerId] && !userLocal[ownerId].localCategoryCalls) {
      userLocal[ownerId].localCategoryCalls = {}
    }
    userLocal[ownerId].localCategoryCalls[forecastCategory.id] = {
      name: forecastCategory.name,
      value: call
    }
    setForecastByUserLocal(userLocal)
  }, [forecastByUserLocal])

  const removeForecastByUserLocalCategoryCall = useCallback((update) => {
    const userLocal = cloneDeep(forecastByUserLocal)
    const { forecast, forecastCategory } = update
    const { current } = forecast
    const { ownerId } = current

    if (userLocal[ownerId] && userLocal[ownerId].localCategoryCalls && has(userLocal[ownerId].localCategoryCalls, forecastCategory.id)) {
      delete userLocal[ownerId].localCategoryCalls[forecastCategory.id]
    }
    setForecastByUserLocal(userLocal)
  }, [forecastByUserLocal])

  const uniqOppCount = useMemo(() => {
    const objectsList = forecast?.current?.objectsList ?? []
    return filter(uniqBy(objectsList, (o) => o.objectId), (o) => o.objectId !== '').length
  }, [forecast])

  const isPastForecastPeriod = useMemo(() => {
    return cursor === -1
  }, [cursor])

  const contextValue = useMemo(() => {
    return {
      key,
      invalidate,
      success,
      successHistory,
      isFetching,
      isFetchingHistory,
      error,
      enabled,
      forecast,
      forecastHistory,
      submitForecast,
      cursor,
      setCursor,
      isPastForecastPeriod,
      teamForecasts,
      clearForecastHistory,
      exportSubmissionHistory,
      searchForecasts,
      saveForecastedCalls,
      getCurrentRepForecastByConfig,
      getCurrentForecastByConfig,
      forecastLocalOppSelections,
      clearLocalForecastOpps,
      clearForecastByUserLocal,
      forecastByUserLocal,
      updateForecastByUserLocal,
      removeForecastByUserLocalCategoryCall,
      uniqOppCount
    }
  }, [
    key,
    invalidate,
    isFetching,
    isFetchingHistory,
    success,
    successHistory,
    error,
    enabled,
    forecast,
    forecastHistory,
    cursor,
    isPastForecastPeriod,
    searchForecasts,
    submitForecast,
    teamForecasts,
    clearForecastHistory,
    exportSubmissionHistory,
    saveForecastedCalls,
    getCurrentRepForecastByConfig,
    getCurrentForecastByConfig,
    forecastLocalOppSelections,
    clearLocalForecastOpps,
    clearForecastByUserLocal,
    forecastByUserLocal,
    updateForecastByUserLocal,
    removeForecastByUserLocalCategoryCall,
    uniqOppCount
  ])

  return <ForecastingContext.Provider value={contextValue}>{children}</ForecastingContext.Provider>
}

export function useForecasting() {
  const context = React.useContext(ForecastingContext)
  if (context === undefined) {
    throw new Error('useForecasting must be used within a ForecastingProvider')
  }
  return context
}
